<!-- =========================================================================================
    File Name: ForgotPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div class="full-page-bg-color" slot="no-body">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img alt="login" class="mx-auto" src="@/assets/images/pages/forgot-password.png">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">Recover your password</h4>
                  <p>Please enter your email address and we'll send you instructions on how to reset your password.</p>
                </div>

                <vs-input class="w-full mb-8" label-placeholder="Email" type="email" v-model="email"/>
                <vs-button class="px-4 w-full md:w-auto" to="/login" type="border">Back To Login</vs-button>
                <vs-button @click="initiatePasswordReset"
                           class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0">Recover Password
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
    };
  },
  methods: {

    initiatePasswordReset() {
      this.$vs.loading();

      this.$http.post(`auth/reset`, {email: this.email})
        .then(response => {
          this.$vs.loading.close();

          if (response.status === 200) {

            this.$gtag.event('auth_fp_success', {
              event_category: 'authentication',
              event_label: 'auth_fp_success',
            });

            return this.$vs.notify({
              color: 'success',
              title: 'Reset Sent',
              text: response.data.data,
              position: 'top-right',
            });
          }

          this.$gtag.event('auth_fp_failure_soft', {
            event_category: 'authentication',
            event_label: 'auth_fp_failure_soft',
          });

        })
        .catch(exception => {
          this.$vs.loading.close();

          if (exception.response) {
            const response = exception.response;

            this.$gtag.event('auth_fp_failure', {
              event_category: 'authentication',
              event_label: 'auth_fp_failure',
            });

            if (response.status === 400) {
              this.$vs.notify({
                color: 'success',
                title: 'Reset Failed to Send',
                text: response.data.data,
                position: 'top-right',
              });
            }

          }

          this.$vs.notify({
            color: 'success',
            title: 'Reset Failed to Send',
            text: 'An unknown error occurred while sending a password reset.',
            position: 'top-right',
          });

        });

    },

  },
};
</script>
